import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

import arrowWhite from '../images/arrow-white.png';
import arrowBlack from '../images/arrow-black.png';
import {
    FONT_GOTHAM_MEDIUM,
    FONT_GOTHAM_LIGHT,
    FONT_NICOLETTA,
} from '../styles/fonts';
import { COLORS, LAYOUT_WIDTH } from '../constants';

enum Size {
    mobileSmall = 'mobileSmall',
    mobile = 'mobile',
    tablet = 'tablet',
    tabletBig = 'tabletBig',
    laptop = 'laptop',
    laptopM = 'laptopM',
    laptopL = 'laptopL',
}

export const deviceSize = {
    mobileSmall: '480px',
    mobile: '580px',
    mobileBig: '658px',
    tablet: '768px',
    tabletBig: '880px',
    laptop: '1024px',
    laptopM: '1200px',
    laptopL: '1350px',
};

export const device = {
    mobileSmall: `(max-width: ${deviceSize.mobileSmall})`,
    mobileMedium: `(max-width: ${deviceSize.mobile})`,
    mobileBig: `(max-width: ${deviceSize.mobileBig})`,
    tabletMedium: `(max-width: ${deviceSize.tablet})`,
    tabletBig: `(max-width: ${deviceSize.tabletBig})`,
    laptopSmall: `(max-width: ${deviceSize.laptop})`,
    laptopMedium: `(max-width: ${deviceSize.laptopM})`,
    laptopLarge: `(max-width: ${deviceSize.laptopL})`,
};

export const media = {
    mobileSmall: (css) =>
        `@media (max-width: ${deviceSize.mobileSmall}) {${css}}`,
    mobile: (css) => `@media (max-width: ${deviceSize.mobile}) {${css}}`,
    mobileBig: (css) => `@media (max-width: ${deviceSize.mobileBig}) {${css}}`,
    tablet: (css) => `@media (max-width: ${deviceSize.tablet}) {${css}}`,
    tabletBig: (css) => `@media (max-width: ${deviceSize.tabletBig}) {${css}}`,
    laptopSmall: (css) => `@media (max-width: ${deviceSize.laptop}) {${css}}`,
    laptopMedium: (css) => `@media (max-width: ${deviceSize.laptopM}) {${css}}`,
    laptopLarge: (css) => `@media (max-width: ${deviceSize.laptopL}) {${css}}`,
};

export const FlexSpacer = styled.div`
    flex-grow: 1;
`;

export const RoundedButtonStyles = css`
    display: inline-block;
    position: relative;
    outline: none;
    background: none;

    border: 1px solid #a60424;
    border-radius: 33px;
    color: #000000;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 14px 48px 14px 48px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: #ffffff;
        background-color: #a60424;
        box-shadow: 0 15px 54px 0 rgba(191, 16, 50, 0.22);
        cursor: pointer;
    }
`;

export const RoundedButton = styled.a`
    ${RoundedButtonStyles}
`;

export const RoundedButtonInternalLink = styled(Link)`
    ${RoundedButtonStyles}
`;

export const RoundedButtonWithArrow = styled(RoundedButton)`
    position: relative;
    padding: 22px 74px 22px 48px;

    &:after {
        content: '';
        position: absolute;
        height: 9px;
        width: 13px;
        right: 48px;
        top: 50%;
        transform: translateY(-50%);

        background-image: url(${arrowBlack});
        background-size: cover;
    }

    &:hover {
        &:after {
            background-image: url(${arrowWhite});
        }
    }
`;

export const ActionButton = styled(RoundedButton)`
    font-size: 18px;
    padding: 17px 45px;
    background-color: #a60424;
    color: #ffffff;

    @media ${device.laptopSmall} {
        align-self: auto;
    }

    @media ${device.tabletMedium} {
        font-size: 16px;

        padding: 14px 24px;
    }
`;

export const HighlightedText = styled('div')<{ marginBottom?: number }>`
    display: flex;
    align-items: center;
    font-family: ${FONT_GOTHAM_MEDIUM};
    color: #a60424;
    height: 52px;
    font-size: 24px;
    margin: 0;
    margin-bottom: ${({ marginBottom }) => marginBottom || 72}px;
    white-space: nowrap;

    @media ${device.laptopMedium} {
        font-size: 20px;
        margin-bottom: 42px;
    }

    @media ${device.laptopSmall} {
        justify-content: center;
    }

    @media ${device.mobileMedium} {
        font-size: 16px;
        text-align: center;
        justify-content: center;
        white-space: normal;
    }
`;

export const Spacer = styled.div`
    flex-shrink: 0;
    width: calc((100vw - 1100px) / 2);
`;

export const ContentMain = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    max-width: ${LAYOUT_WIDTH}px;
    width: 100%;
    padding: 12px;
`;

const ImagesStyles = css`
    border-radius: 22px;
    box-shadow: 0 1px 2px 0 rgba(25, 27, 26, 0.1),
        0 1px 8px 0 rgba(25, 27, 26, 0.08);

    @media ${device.tabletMedium} {
        max-width: 95%;
    }
`;

export const RichTextView = styled('div')<{ fontSize?: number }>`
    color: #000000;
    font-family: ${FONT_GOTHAM_LIGHT};
    font-size: ${({ fontSize = 20 }) => `${fontSize}px`};
    line-height: 26px;

    @media ${device.mobileMedium} {
        font-size: 16px;
    }

    h2 {
        font-size: 1.8em;
        margin: 32px 0 28px 0;
    }

    h3 {
        font-size: 1.5em;
        margin: 28px 0 26px 0;
    }

    h4 {
        font-size: 1.1em;
        margin: 20px 0 18px 0;
    }

    ul {
        margin: 0;
        padding: 0 0 1rem 1em;
        list-style: none;

        li {
            &:before {
                content: '\\25CB';
                color: #000;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
            }
        }

        li:before {
            content: '\\2022';
            color: #000;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
        }
    }

    a {
        color: #a60424;

        &:hover {
            text-decoration: underline;
        }
    }

    p {
        margin: 0 0 1rem 0;
        padding: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    blockquote {
        display: inline-block;
        position: relative;
        margin: 36px 0 36px 62px;
        padding: 0;
        padding-left: 36px;
        border-left: 5px solid #a60424;
        font-style: italic;

        @media ${device.tabletMedium} {
            margin: 16px 0 16px 16px;
        }
    }

    .video {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 24px;
    }

    iframe {
        display: block;
        padding: 24px;
        margin: 0 auto;
    }

    table {
        border-collapse: collapse;
        width: 100%;

        @media ${device.laptopSmall} {
            overflow-y: scroll;
            display: block;
        }
    }

    table,
    th,
    td {
        border: 1px solid black;
    }

    td {
        padding: 12px;
    }

    img {
        ${ImagesStyles};
        display: block;
        margin: 24px auto;
    }

    figure {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        margin: 36px 0;

        figcaption {
            margin-top: 10px;
            font-style: italic;
        }

        img {
            margin: 0;
        }
    }
`;

export const GlideBulletButton = styled.button`
    background-color: hsla(0, 0%, 50%, 0.2);
    width: 9px;
    height: 9px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;

    &:focus {
        outline: none;
    }

    &:focus,
    &:hover {
        border: 2px solid #a60424;
        background-color: hsla(0, 0%, 100%, 0.5);
    }

    &[class*='active'] {
        background-color: #a60424;
    }
`;

export const Signature = styled('div')<{ fontSize?: number }>`
    font-family: ${FONT_NICOLETTA};
    font-size: ${({ fontSize }) => fontSize || 80}px;
    line-height: 94px;
    color: #6e5f67;
    white-space: nowrap;
`;

export const PointBlock = styled.div`
    max-width: 366px;
    width: 100%;
    background-color: #f7f0ed;
    border-radius: 22px;
`;

export const HiddenLabel = styled.span`
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    width: 0px;
    height: 0px;
    bottom: 0px;
    left: 0px;
`;

export const CustomScrollBar = css`
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        z-index: 100;
    }
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0);

    &::-webkit-scrollbar-thumb {
        background-color: ${COLORS.lightGray};
        border-radius: 6px;
    }
`;
